/* @import '~antd/dist/antd.css'; */

Body,
.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100vw;
  height: 100vh;
  background-color: #e9f1f8;
}

.page {
  overflow: auto;
  flex-grow: 1;
  height: calc(100vh - 214px);
  line-height: 1.5;
}